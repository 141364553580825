@use '../abstracts/mixins';
@use '../abstracts/types';

html {
  font-size: 62.5%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  margin: 0;
}

@each $type, $type-map in types.$types {
  .type-#{$type} {
    @each $type-prop, $type-val in $type-map {
      @if $type-prop != respond-to {
        #{$type-prop}: $type-val;
      }
      @else {
        @each $response-size, $response-map in $type-val {
          @include mixins.respond-to($response-size) {
            @each $response-prop, $response-val in $response-map {
              #{$response-prop}: $response-val;
            }
          }
        }
      }
    }
  }
}
