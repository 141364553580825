@use '../../../assets/scss/abstracts/mixins';

.wrapper {
  padding-left: var(--root-container-wrapper-padding);
  padding-right: var(--root-container-wrapper-padding);

  @include mixins.respond-to(md) {
    --root-container-wrapper-padding: 3.9rem;
  }
}

.container {
  margin-left: auto;
  margin-right: auto;
  max-width: 111rem;
}
