.error {
  color: var(--clr-alert-danger);
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

.form > * + * {
  margin-top: 1rem;
}

